const Config = {
  connected: function () {
    this.requestNextMedia()
  },

  received: function (data) {
    if (this.mediaPlayingInterval) {
      clearInterval(this.mediaPlayingInterval)
    }

    if (!data["html"]) {
      this.controller.spotlightTarget.innerHTML = this.controller.placeholderHTML
    } else {
      this.controller.spotlightTarget.innerHTML = data["html"]
    }

    if (data["is_video"]) {
      clearInterval(this.mediaPlayingInterval)
      this.requestNextMedia(true)
      return
    }

    this.mediaPlayingInterval = setInterval(() => {
      this.requestNextMedia()
    }, 5000)
  },

  disconnected: function () {
    /**
     * TODO:
     * 1. Continue displaying media (cached)
     */
  },

  requestNextMedia: function(videoStarting = false) {
    // If the current media playing is a video, wait until the video finishes and then play the next media
    const vidOnSpotlight = this.controller.spotlightTarget.querySelector("video")
    if (vidOnSpotlight && videoStarting) {
      vidOnSpotlight.addEventListener("ended", () => {
        this.requestNextMedia(false)
      }, { once: true })

      return
    }
    this.perform("display_media", {})
  }
}

export default Config